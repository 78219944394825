import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div>
    <h1>My Layout</h1>
    <div>{children}</div>
  </div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`My MDX`}</h1>
    <p>{`some content`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      